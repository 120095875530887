#wins{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 1;
}

header {
    height: 10vh;
    display: flex;
    align-items: center;
    position: relative;
}

header::before{
    content: '';
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    width: calc(100% - 10px);
    height: 2px;
    background-color: #e5e5e5;
}

header h1{
    font-size: 2.5em;
    color: rgb(252, 163, 17);
}

.rank h2{
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    font-size: 1.8em;
    color: rgb(20, 33, 61);
}

.rank table {
    margin-top: 10px;
    border-collapse: collapse;
}

.rank table td,
.rank table th{
    padding: 0 20px;
    height: 40px;
    color: #000;
}

.rank table tr:nth-child(odd) td{
    background-color: rgb(249, 249, 249);
}

.rank table tr:nth-child(1) th{
    border-bottom: 2px solid #000;
}

.rank table th{
    font-size: 1em;
}

.rank table td:nth-child(1){
    text-align: center;
}

.chart{
    position: relative;
    width: 100%;
    height: 75vh;
    padding-bottom: 10px;
}


.Games h2,
.Games h3{
    height: 40px;
    color: #14213d;
    font-size: 1.7em;
}

.Games h3{
    font-size: 1.3em;
}

.Games {
    padding: 10px;
}

.Games:nth-child(odd){
    background-color: rgb(252, 163, 17);
    border-radius: 10px;
}

.Games:nth-child(odd) h2,
.Games:nth-child(odd) h3{
    color: #fff;
}

#wins select{
    margin-bottom: 10px;
    min-width: 100px;
    padding: 5px 0;
    text-align: center;
    font-size: 1em;
    outline: none;
    color: #14213d;
    border: 2px solid rgb(253, 163, 18);
    background: rgba(253, 163, 18, 0.4);
    border-radius: 5px;
}

#wins .Games:nth-child(odd) select{
    border-color: rgb(20, 33, 61);
    background: rgba(20, 33, 61, 0.7);
    color: #fff;
}

@media screen and (min-width: 1000px) {
    
    .Bars{
        display: flex;
    }
    
    .Bar{
        width: 50%;
    }
}