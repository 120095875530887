* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App{
    display: flex;
}

.container{
    position: relative;
    top: 0;
    left: 100%;
    transform: translateX(-100%);
    width: calc(100% - 70px);
    height: 100vh;
    padding: 0 10px;
    transition: 0.5s ease-in-out;
    overflow-y: auto;
}

/* .menuClosed{
    width: calc(100% - 70px);
}

.menuOpened{
    width: calc(100% - 350px);
} */


::-webkit-scrollbar {
    width: 10px;
  }
  
::-webkit-scrollbar-track {
    background: #fff;
  }
  
::-webkit-scrollbar-thumb {
    background-color: #14213d;
    border: 3px solid #fff;
    border-radius: 5px;
  }