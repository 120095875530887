.playerInfo {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding-right: 50px;
}

.playerName {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

.playerName::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.gameStats .selectYear select{
  margin-bottom: 10px;
  min-width: 100px;
  padding: 5px 0;
  text-align: center;
  font-size: 1em;
  outline: none;
  color: #14213d;
  border: 2px solid rgb(253, 163, 18);
  background: rgba(253, 163, 18, 0.4);
  border-radius: 5px;
}

.gameStats:nth-child(even) .selectYear select{
  border-color: rgb(20, 33, 61);
  background: rgba(20, 33, 61, 0.7);
  color: #fff;
}

.playerInfo h1 {
  color: #14213d;
}

.playerInfo h1 span {
  padding-left: 5px;
  color: #fca311;
  font-size: 0.8em;
}

.playerInfo .scores {
  margin-top: 20px;
  display: grid;
  grid-auto-rows: 200px;
  justify-content: center;
  width: 100%;
  height: auto;
}

.playerInfo .scores .GridBox:nth-child(3) > div,
.playerInfo .scores .GridBox:nth-child(4) > div,
.playerInfo .scores .GridBox:nth-child(5) > div {
  color: #000;
}

.playerInfo .scores > div {
  position: relative;
}

.playerInfo .scores div p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
  text-align: center;
  color: #14213d;
  font-size: 1em;
  font-weight: bold;
  font-family: sans-serif;
}

.playerInfo .scores div > div {
  position: relative;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.9em;
}

.roundPoint {
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  height: 65vh;
  transition: 0.5s;
}

.roundPoint .roundDetails {
  height: 50px;
}

.roundPoint .roundChart {
  width: 100%;
  height: calc(100% - 50px);
  /* max-height: calc(65vh - 83px); */
  padding-bottom: 20px;
}

.roundChart select {
  margin-bottom: 10px;
  min-width: 100px;
  padding: 5px 0;
  text-align: center;
  font-size: 1em;
  outline: none;
  color: #14213d;
  border: 2px solid rgb(253, 163, 18);
  background: rgba(253, 163, 18, 0.4);
  border-radius: 5px;
}

.gameStats {
  padding: 9px;
  height: auto;
  /* min-height: 100vh; */
}

.gameStats:nth-child(even) {
  border-radius: 9px;
  background: #fca311;
}

.gameStats h2 {
  height: 40px;
  margin-bottom: 10px;
}

.gameStats .roundDetails img {
  margin: 0;
  width: 80%;
  height: auto;
  transform: rotateZ(0);
  transition: 0.5s;
}

.gameStats:nth-child(odd) h2 {
  display: flex;
  align-items: center;
  color: #14213d;
}

.gameStats:nth-child(odd) h2 button {
  position: relative;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: rgba(20, 33, 61, 0.7);
  outline: none;
  border: 2px solid rgb(20, 33, 61);
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  transition: 0.3s;
}

.gameStats:nth-child(odd) h2 button:hover {
  box-shadow: inset 3px 3px 6px rgba(20, 33, 61, 0.9);
  transition: 0.3s;
}

.gameStats:nth-child(odd) h2 button:active {
  background: rgb(20, 33, 61);
  box-shadow: inset 3px 3px 6px rgba(20, 33, 61, 0.9);
  transition: 0.3s;
}

.gameStats:nth-child(even) h2 {
  display: flex;
  align-items: center;
  color: #fff;
}

.gameStats:nth-child(even) h2 button {
  position: relative;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: none;
  outline: none;
  border: 2px solid #b37410;
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  transition: 0.3s;
}

.gameStats:nth-child(even) h2 button:hover {
  box-shadow: inset 3px 3px 6px #95600cb4;
  transition: 0.3s;
}

.gameStats:nth-child(even) h2 button:active {
  background: #b37410;
  box-shadow: inset 3px 3px 6px #95600cb4;
  transition: 0.3s;
}

.gameStats:nth-child(even) .roundChart select {
  border-color: rgb(20, 33, 61);
  background: rgba(20, 33, 61, 0.7);
  color: #fff;
}

@media screen and (min-width: 800px) {
  .playerInfo .scores {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(100% / 4));
    grid-auto-rows: 10vh;
  }

  .playerInfo .scores .GridBox:nth-child(1) {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .gameStats:nth-child(odd) .GridBox:nth-child(1)::after,
  .gameStats:nth-child(odd) .GridBox:nth-child(3)::after,
  .gameStats:nth-child(odd) .GridBox:nth-child(4)::after {
    content: "";
    width: 2px;
    height: 80%;
    background: #d0d0d0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .playerInfo .scores .GridBox:nth-child(2) {
    grid-column: 3/5;
    grid-row: 1/2;
  }

  .playerInfo .scores .GridBox:nth-child(3) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .playerInfo .scores .GridBox:nth-child(4) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .playerInfo .scores .GridBox:nth-child(5) {
    grid-column: 3/5;
    grid-row: 2/3;
  }

  .gameStats:nth-child(even) .GridBox:nth-child(1)::after,
  .gameStats:nth-child(even) .GridBox:nth-child(3)::after,
  .gameStats:nth-child(even) .GridBox:nth-child(4)::after {
    content: "";
    width: 2px;
    height: 80%;
    background: #d2870e;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 800px) {
  .gameStats h2 {
    height: 40px;
    margin-bottom: 10px;
    font-size: 1.2em;
  }

  .playerInfo .scores {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(100% / 2));
    grid-auto-rows: 10vh;
  }

  .playerInfo .scores .GridBox:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .gameStats:nth-child(odd) .GridBox:nth-child(1)::after,
  .gameStats:nth-child(odd) .GridBox:nth-child(3)::after {
    content: "";
    width: 2px;
    height: 80%;
    background: #d0d0d0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .gameStats:nth-child(odd) .GridBox:nth-child(5)::after {
    content: "";
    width: 80%;
    height: 2px;
    background: #d0d0d0;
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translateX(-50%);
  }

  .gameStats:nth-child(odd) .GridBox:nth-child(5)::before {
    content: "";
    width: 80%;
    height: 2px;
    background: #d0d0d0;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
  }

  .playerInfo .scores .GridBox:nth-child(2) {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .playerInfo .scores .GridBox:nth-child(3) {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .playerInfo .scores .GridBox:nth-child(4) {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  .playerInfo .scores .GridBox:nth-child(5) {
    grid-column: 1/3;
    grid-row: 2/3;
  }

  .gameStats:nth-child(even) .GridBox:nth-child(1)::after,
  .gameStats:nth-child(even) .GridBox:nth-child(3)::after {
    content: "";
    width: 2px;
    height: 80%;
    background: #d2870e;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .gameStats:nth-child(even) .GridBox:nth-child(5)::after {
    content: "";
    width: 80%;
    height: 2px;
    background: #d2870e;
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translateX(-50%);
  }

  .gameStats:nth-child(even) .GridBox:nth-child(5)::before {
    content: "";
    width: 80%;
    height: 2px;
    background: #d2870e;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 450px) {
    .gameStats h2{
        margin-bottom: 0;
    }

  .roundDetails h2 {
    height: auto;
    margin-bottom: 0;
  }

  .roundPoint {
    position: relative;
    padding-bottom: 0;
    width: 100%;
    height: auto;
    transition: 0.5s;
  }

  .roundPoint .roundDetails {
    height: auto;
  }

  .roundPoint .roundChart {
    width: 100%;
    height: calc(65vh - 50px);
    max-height: 65vh;
    /* padding-bottom: 90px; */
  }

  .roundPoint:last-child .roundChart {
    width: 100%;
    height: calc(65vh - 130px);
    max-height: 45vh;
  }

  .playerInfo .scores {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100%);
    grid-auto-rows: 10vh;
  }

  .playerInfo .scores .GridBox:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .gameStats:nth-child(odd) .GridBox:nth-child(1)::after,
  .gameStats:nth-child(odd) .GridBox:nth-child(3)::after {
    content: "";
    width: 2px;
    height: 80%;
    background: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .gameStats:nth-child(odd) .GridBox:nth-child(5)::after {
    content: "";
    width: 80%;
    height: 2px;
    background: #d0d0d0;
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translateX(-50%);
  }

  .gameStats:nth-child(odd) .GridBox:nth-child(5)::before {
    content: "";
    width: 80%;
    height: 2px;
    background: #d0d0d0;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
  }

  .playerInfo .scores .GridBox:nth-child(2) {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .playerInfo .scores .GridBox:nth-child(3) {
    grid-column: 1/2;
    grid-row: 5/6;
  }
  .playerInfo .scores .GridBox:nth-child(4) {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .playerInfo .scores .GridBox:nth-child(5) {
    grid-column: 1/2;
    grid-row: 3/4;
  }

  .gameStats:nth-child(odd) .GridBox:nth-child(5) p {
    color: #fca311;
  }

  .gameStats:nth-child(even) .GridBox:nth-child(5) p {
    color: #fff;
  }

  .gameStats:nth-child(even) .GridBox:nth-child(1)::after,
  .gameStats:nth-child(even) .GridBox:nth-child(3)::after {
    content: "";
    width: 2px;
    height: 80%;
    background: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .gameStats:nth-child(even) .GridBox:nth-child(5)::after {
    content: "";
    width: 80%;
    height: 2px;
    background: #d2870e;
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translateX(-50%);
  }

  .gameStats:nth-child(even) .GridBox:nth-child(5)::before {
    content: "";
    width: 80%;
    height: 2px;
    background: #d2870e;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
  }
}
