* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#logo .sendEmail img {
  width: 90%;
  height: auto;
}

#logo .sendEmail {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3b64bc;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
}

.PlayerSettings {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3b64bc;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: none;
  transition: 0.3s;
}

.PlayerSettings:active,
#logo .sendEmail:active {
  background: #2a4684;
  transition: 0.3s;
}

#logo .sendEmail:hover img {
  transform: rotateZ(360deg);
  transition: 0.5s;
}

.PlayerSettings:hover img {
  transform: rotateZ(180deg);
  transition: 0.5s;
}

.PlayerSettings img {
  width: 90%;
  height: auto;
  transform: rotateZ(0deg);
  transition: 0.5s;
}


nav {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0);
  width: 300px;
  height: 100vh;
  box-shadow: 5px 0 30px rgba(0, 0, 0, 0.22);
  transition: 0.5s ease-in-out;
  z-index: 5;
  background: #fff;
}

.btn-open {
  position: absolute;
  top: 15px;
}

#menu {
  width: 100%;
  height: calc(100vh - 130px);
  overflow-y: auto;
}

.category-label {
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
}

.category-label h2 {
  color: #cccccc;
  font-weight: lighter;
  font-size: 1.4em;
}

.category-item li {
  position: relative;
  width: 100%;
  height: 40px;
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
  padding: 0 10px;
  color: #000;
  text-decoration: none;
  z-index: 4;
}

.link span {
  flex-grow: 2;
  font-size: 1.1em;
  z-index: 5;
}

.link::before {
  content: "";
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  width: 0%;
  height: 3px;
  background: #14213d;
  z-index: 4;
  transition: 0.3s ease-in;
}

.link:hover::before {
  width: 100%;
  transition: 0.3s ease-in;
}

.active {
  background-color: #fca311;
  font-weight: 500;
}

.opened {
  left: 0;
  /* transform: translateX(0); */
}

.closed {
  left: -350px;
  /* transform: translateX(-100%); */
}

.newGame {
  position: absolute;
  right: 10px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4c956c;
  box-shadow: 0 0 0 #2c6e49;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.newGame:hover,
.newGame:active {
  box-shadow: inset 3px 3px 0 #2c6e49;
}

.GameLog {
  position: absolute;
  right: 40px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3b64bc;
  box-shadow: 0 0 0 #2a4684;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.GameLog:hover,
.GameLog:active {
  box-shadow: inset 3px 3px 0 #2a4684;
}

.settings {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}


.won {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 50%;
  z-index: 15;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #cccccc;
}

.won .choosebtns {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.won button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 10px;
  outline: none;
  font-size: 1.2em;
  cursor: pointer;
  background: #fca311;
  color: #14213d;
  transition: 0.3s;
}

.won button:hover,
.won button:active {
  background: #14213d;
  color: #fff;
  transition: 0.3s;
}