.add{
    position: absolute;
}

.btns {
    display: flex;
}

.btns button{
    font-size: 1em;
}

