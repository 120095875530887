.SettingsEdit{
    margin-top: 10px;
}

.SettingsEdit h1{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fca311;
    height: auto;
    padding: 0;
    margin: 0;
    font-size: 1.3em;
}

.SettingsEdit .header{
    display: flex;
    align-items: center;
}

.SettingsEdit .header button{
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3b64bc;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 30px;
    height: 30px;
}

.SettingsEdit .header button img{
    width: 90%;
    height: auto;
}

.DoneEdit{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4c956c;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 300px;
    height: 35px;
    color: #fff;
    font-size: 1em;
    padding: 5px;
    transition: 0.3s;
    cursor: pointer;
}

.DoneEdit:hover{
    box-shadow: inset 5px 5px 0 #2c6e49;
    transition: 0.3s;
}

.DoneEdit:active{
    background: #2c6e49;
    transition: 0.3s;
}

.DoneEdit img{
    margin: 0 10px;
    width: auto;
    height: 90%;
}

.SettingsEdit input{
    margin: 10px 0;
    width: 300px;
    padding: 7px;
    font-size: 1.1em;
    color: #14213d;
    outline: none;
    border: 2px solid #ccc;
    border-radius: 9px;
    transition: 0.3s;
}


