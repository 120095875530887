.field select {
    margin: 10px 0 20px 0;
    width: 100%;
    padding: 7px;
    font-size: 1.1em;
    color: #14213d;
    outline: none;
    border: 2px solid #ccc;
    border-radius: 9px;
    transition: 0.3s;
}

.field select:hover{
    border: 2px solid #14213d;
    transition: 0.3s;
}

.field select:focus {
    border: 2px solid #fca311;
}