.add form{
    width: 80%;
    max-width: 400px;
}

.add .field {
    width: 100%;
}

.add form input{
    width: 100%;
}

.btns {
    display: flex;
}

.btns button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    height: 40px;
}

.btns button img{
    margin: 0;
    width: auto;
    height: 100%;
}

.btns button span{
    padding: 0 10px;
}

h1{
    color: #fca311;
    margin-bottom: 20px;
}

@media screen and (max-width: 380px) {
    .btns {
        flex-wrap: wrap;
    }
}